<template>
  <el-container>
    <el-header
      class="hidden-xs-only"
      v-if="!$route.path.includes('/python') || !hideNavBar"
      :class="{'home': $route.path === '/home', 'study': ['/study', '/payment', '/qa'].includes($route.path)}"
      :style="{'background': scrollTop === 0 ? 'transparent': `rgba(231, 226, 251, ${scrollTop / 100})`}">
      <div class="container">
        <div class="left">
          <img class="logo" src="@/assets/img/logo.svg" alt="" @click="$router.push('/home')">
        </div>
        <div v-if="user.userId && $route.path !== '/home'" class="right">
          <div class="nav-item">
            <span :class="{ active: $route.path === '/study' }" @click="$router.push('/study')">课程目录</span>
            <span :class="{ active: $route.path === '/payment' }" @click="$router.push('/payment')">升级会员</span>
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover">
              <div class="copy-content">
                <div class="text">邀请好友一起加入《跟ChatGPT学Python》！通过您的专属链接邀请好友注册后，您和您的好友均可免费获得额外的 10 次GPT-4问答机会。</div>
                <div class="btn">
                  <el-button
                    type="primary"
                    size="mini"
                    v-clipboard:copy="inviterUrl"
                    v-clipboard:success="copySuccess"
                  >复制专属链接</el-button>
                </div>
              </div>
              <template  #reference>
                <span>邀请好友</span>
              </template>
            </el-popover>
            <span :class="{ active: $route.path === '/qa' }" @click="$router.push('/qa')">常见问题</span>
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover">
              <div class="contact-content">
                <img src="@/assets/img/kefu.jpeg" alt="">
              </div>
              <template  #reference>
                <span>添加真人助教</span>
              </template>
            </el-popover>
          </div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              个人中心
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><span class="label">用户ID: </span>{{ user.nickName }}</el-dropdown-item>
              <el-dropdown-item v-if="user.endTime"><span class="label">AI调用有效期: </span>{{ user.endTime | convertTime }}</el-dropdown-item>
              <el-dropdown-item><span class="label">ChatGPT调用次数/剩余次数: {{ Number(user.gptUseCount) }} / {{ Number(user.gptBalanceCount) }}</span></el-dropdown-item>
              <!-- <el-dropdown-item v-else><span class="label">ChatGPT调用次数: {{ Number(user.gptUseCount) }}</span></el-dropdown-item> -->
              <el-dropdown-item><span class="label">邀请好友数量: </span>{{ user.invitedCount }}</el-dropdown-item>
              <!-- <el-dropdown-item v-clipboard:copy="inviterUrl" v-clipboard:success="inviter"><span class="label">专属邀请链接: </span>{{ inviterUrl}}</el-dropdown-item> -->
              <el-dropdown-item command="logout">
                <div class="dropdown-item-logout">退出登录</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <div class="page-main">
      <router-view></router-view>
    </div>
    <el-footer v-if="$route.path === '/home'" class="bg-black hidden-sm-and-up">
      Copyright © 2023, 道可道智能科技有限公司&nbsp;<a href="https://beian.miit.gov.cn">皖ICP备2022011146号-3</a>
    </el-footer>
    <el-footer v-if="$route.path === '/home'" class="bg-black hidden-xs-only">
      Copyright © 2023, 道可道智能科技有限公司&nbsp;<a href="https://beian.miit.gov.cn">皖ICP备2022011146号-3</a>
    </el-footer>
    <mask-page v-if="openMask" />
  </el-container>
</template>
<script>
import maskPage from '@/components/mask'
import { mapGetters, mapState } from 'vuex'
import { setItem } from '@/utils/storage'
import EventBus from '@/components/course/EventBus'
export default {
  name: 'layout',
  computed: {
    ...mapGetters(['openMask', 'inviterUrl', 'isMember', 'scrollTop']),
    ...mapState(['user']),
    isFirstLogin() {
      return this.user.isFirstLogin
    },
    isMember() {
      return this.user.isMember
    }
  },
  data() {
    return {
      hideNavBar: false
    }
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path.includes('/python')) {
          EventBus.$on('shortcut-action', (type, isCollapse) => {
            if (type === 3 && isCollapse === true) {
              this.hideNavBar = true
            } else if (type === 3 && isCollapse === false) {
              this.hideNavBar = false
            }
          })
        }
      },
      immediate: true
    }
  },
  filters: {
    convertTime(time) {
      if (!time) return
      const date = new Date(time)
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
  },
  mounted() {
    const { icode } = this.$route.query
    if (icode) {
      setItem(icode, 'icode')
    }
    // if (this.user && this.user.userId && this.isFirstLogin !== 1 && !this.isMember) {
    //   window.payTimer = setTimeout(() => {
    //     this.$store.dispatch('goPaymentAction', '亲爱的读者，感觉不错的话请升级会员吧。')
    //     clearTimeout(window.payTimer)
    //   }, 60000)
    // }
  },
  components: {
    maskPage
  },
  methods: {
    handleCommand(command) {
      this[command]()
    },
    logout() {
      this.$store.commit('SET_USER', {})
      this.$router.push('/home')
    },
    inviter() {
      this.$message.success('邀请链接已复制！')
    },
    copySuccess() {
      this.$message.success('邀请链接已复制!')
    }
  }
}
</script>
<style scoped lang="scss">
.el-container {
  a {
    color: #000;
  }
  .el-header {
    display: flex;
    justify-content: center;
    height: 70px !important;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    border-bottom: 1px solid #E0E0E0;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 96vw;
      height: 100%;
      .left {
        display: flex;
        align-items: center;
        cursor: pointer;
        .logo {
          position: relative;
          width: 60px;
          height: 60px;
          margin-right: 32px;
          top: 6px;
          left: 0px;
        }
        .nav-item {
          margin-right: 24px;
          text-decoration: none;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .notice-icon {
          width: 22px;
          margin-right: 20px;
        }
        .nav-item {
          display: flex;
          width: 460px;
          height: 100%;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          margin-right: 30px;
          span:hover {
            color: #835AFD;
            cursor: pointer;
          }
          span.active {
            color: #835AFD;
          }
        }
      }
      .icon-gerenzhongxin {
        color: #000;
        font-size: 20px;
      }
    }
  }
  .el-header.home {
    background: rgba($color: #141117, $alpha: 1);
    border-bottom: none;
    height: 100px !important;
    .container {
      .right {
        .nav-item {
          span {
            color: #fff;
          }
        }
        .el-dropdown-link {
          color: #fff;
        }
      }
    }
  }
  .el-header.study {
    background: transparent;
    border-bottom: none;
    // .container {
    //   .right {
    //     .nav-item {
    //       span {
    //         color: #fff;
    //       }
    //     }
    //     .el-dropdown-link {
    //       color: #fff;
    //     }
    //   }
    // }
  }
  a.router-link-active {
    color: #835AFD;
  }
  .el-dropdown-menu__item.logout {
    text-align: center;
  }
  .page-main {
    width: 100%;
    min-height: 100vh;
  }
  .el-footer {
    width: 100vw;
    height: 60px;
    text-align: center;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .el-footer a {
    color: #999;
  }
  .el-footer.hidden-sm-and-up {
    font-size: 60px;
  }
}
</style>
<style lang="scss">
.contact-content {
  display: flex;
  justify-content: center;
  img {
    width: 300px;
    height: 300px;
  }
}
</style>
